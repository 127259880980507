<template>
  <v-dialog
    persistent
    v-model="dialog"
    @keydown.esc="dialog = false"
    width="1050"
    scrollable
  >
    <v-form ref="form" @submit.prevent lazy-validation v-model="valid">
      <v-card class="elevation-3">
        <v-card-title class="primary text-h5 white--text">
          {{ modalText }}
        </v-card-title>
        <v-card-text class="pa-4">
          <v-row>
            <v-col sm="12" md="4">
              <ExciseStratum
                label="Stratum"
                v-model="selectedExciseExceptions.StratumID"
                :items="stratum"
                :rules="[(v) => !!v || 'required']"
              ></ExciseStratum>
            </v-col>
            <v-col sm="12" md="4">
              <BaseDatePickerWithText
                label="Invoice Date mm/dd/yyyy"
                v-model="selectedExciseExceptions.InvoiceDate"
                :min="selectedProject.AuditStart"
                :max="selectedProject.AuditEnd"
                minInvalid="must be after Audit Start"
                maxInvalid="must be before Audit End"
                :rules="[(v) => !!v || 'required']"
              ></BaseDatePickerWithText>
            </v-col>
            <v-col sm="12" md="4">
              <v-text-field
                label="Document Number"
                v-model="selectedExciseExceptions.DocumentNumber"
                :rules="[
                  (v) => !!v || 'required',
                  (v) => v.length < 50 || 'max 50 characters',
                ]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12" md="4">
              <BaseCombobox
                label="Vendor Customer"
                v-model="selectedExciseExceptions.VendorCustomer"
                :items="filteredVendorCustomer"
                :rules="[(v) => !!v || 'required']"
              ></BaseCombobox>
            </v-col>
            <v-col sm="12" md="4">
              <LuExciseIssues
                label="Issue"
                v-model="selectedExciseExceptions.IssueID"
                :rules="[(v) => !!v || 'required']"
              ></LuExciseIssues>
            </v-col>
            <v-col sm="12" md="4">
              <LuExciseTransactionType
                :items="availableTransactionTypes"
                label="Transaction Type"
                v-model="selectedExciseExceptions.TransactionTypeID"
                :rules="[(v) => !!v || 'required']"
                clearable
              ></LuExciseTransactionType>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12" md="4">
              <LuExciseTaxTypes
                :items="availableTaxTypes"
                label="Tax Type"
                v-model="selectedExciseExceptions.TaxTypeID"
                :rules="[(v) => !!v || 'required']"
                clearable
              ></LuExciseTaxTypes>
            </v-col>
            <v-col sm="12" md="8">
              <v-text-field
                label="Description"
                v-model="selectedExciseExceptions.Description"
                :rules="[(v) => !!v || 'required']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12" md="4">
              <LuCounty
                autocomplete="new-password"
                label="Delivered To County"
                sortProperty="ID"
                v-model="selectedExciseExceptions.DeliveredToCountyID"
                :items="filteredCounties"
                :rules="[(v) => !!v || 'required']"
              ></LuCounty>
            </v-col>
            <v-col sm="12" md="4">
              <LuCity
                autocomplete="new-password"
                label="Delivered To City"
                v-model="selectedExciseExceptions.DeliveredToCityID"
                :items="filteredCities"
                clearable
              ></LuCity>
            </v-col>
            <v-col v-if="filteredSpecialDistrict.length > 0" sm="12" md="4">
              <LuSpecialDistrict
                label="Special District"
                v-model="selectedExciseExceptions.SpecialDistrictID"
                :items="filteredSpecialDistrict"
                clearable
              ></LuSpecialDistrict>
            </v-col>
          </v-row>
          <v-row>
            <!-- <v-text-field
              label="Taxable Amount"
              v-model="selectedExciseExceptions.TaxableAmount"
              type="number"
              :rules="[(v) => parseFloat(v) == parseFloat(v) || 'required']"
            ></v-text-field> -->
            <v-col
              sm="12"
              md="3"
              v-if="
                !reconcilliationIssues.includes(
                  selectedExciseExceptions.IssueID
                )
              "
            >
              <v-text-field
                label="Taxable Amount"
                prefix="$"
                v-model="selectedExciseExceptions.TaxableAmount"
                type="number"
                :rules="[(v) => parseFloat(v) == parseFloat(v) || 'required']"
              ></v-text-field>
            </v-col>
            <v-col sm="12" md="3" v-else>
              <v-text-field
                label="Audited Tax"
                prefix="$"
                v-model="auditedTax"
                type="number"
                :rules="[(v) => parseFloat(v) == parseFloat(v) || 'required']"
              ></v-text-field>
            </v-col>
            <v-col sm="12" md="3">
              <v-text-field
                :label="
                  !reconcilliationIssues.includes(
                    selectedExciseExceptions.IssueID
                  )
                    ? 'Tax Paid'
                    : 'Reported Tax'
                "
                v-model="selectedExciseExceptions.TaxPaid"
                prefix="$"
                type="number"
                :rules="[(v) => parseFloat(v) == parseFloat(v) || 'required']"
              ></v-text-field>
            </v-col>
            <v-col sm="12" md="3">
              <v-text-field
                v-if="
                  !reconcilliationIssues.includes(
                    selectedExciseExceptions.IssueID
                  )
                "
                label="Tax Rate"
                :value="formatPercent(taxRate)"
                readonly
              ></v-text-field>
            </v-col>
            <v-col sm="12" md="3">
              <v-text-field
                prefix="$"
                label="Tax Due"
                v-model="taxDue"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12" md="6">
              <BaseCombobox
                label="Reference"
                v-model="selectedExciseExceptions.Reference"
                :items="filteredReferences"
                :rules="[(v) => !!v || 'required']"
              ></BaseCombobox>
            </v-col>
            <v-col sm="12" md="6">
              <v-checkbox
                label="Is Active"
                v-model="selectedExciseExceptions.IsActive"
                :rules="[(v) => v === true || v === false || 'required']"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="saveExciseExceptionsDetails"
            :disabled="saving"
            color="primary"
            >Save</v-btn
          >
          <v-btn outlined color="primary" @click="cancelEntry">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import {get, sync, commit, call} from 'vuex-pathify'
import {ref, reactive, watch, computed, inject} from 'vue'
import ExciseExceptions from '@classes/ExciseExceptions'
import LuCity from '@components/select/a-single/LuCity'
import LuCounty from '@components/select/a-single/LuCounty'
import LuExciseIssues from '@components/select/a-single/LuExciseIssues'
import LuSpecialDistrict from '@components/select/a-single/LuSpecialDistrict'
import ExciseStratum from '@components/select/a-single/ExciseStratum'
import LuExciseTaxTypes from '@components/select/a-single/LuExciseTaxTypes'
import LuExciseTransactionType from '@components/select/a-single/LuExciseTransactionType'
import useExciseTaxRates from '@composables/useExciseTaxRates'
export default {
  components: {
    LuCity,
    LuCounty,
    LuExciseIssues,
    LuSpecialDistrict,
    ExciseStratum,
    LuExciseTaxTypes,
    LuExciseTransactionType,
  },
  props: {
    vendorCustomer: {
      type: Array,
    },
    stratum: {
      type: Array,
    },
    references: {
      type: Array,
    },
    projectDetailsId: {
      type: Number,
    },
  },
  setup(props, context) {
    const store = inject('vuex-store')
    // const store = context?.root?.$store
    // const refs = context.refs
    const {getTaxRate} = useExciseTaxRates(store)
    return {
      getTaxRate,
    }
  },
  data: () => ({
    dialog: false,
    valid: true,
    selectedExciseExceptions: new ExciseExceptions(),
    modalText: '',
    reconcilliationIssues: [8, 10],
    auditedTax: 0,
  }),
  created() {
    this.loadLuCounty()
    this.loadLuCity()
    this.loadLuSpecialDistrict()
    this.loadLuExciseTaxTypes()
    this.loadLuExciseTransactionType()
  },
  computed: {
    ...get('luCounty', ['luCounty']),
    ...get('luCity', ['luCity']),
    ...get('luSpecialDistrict', ['luSpecialDistrict']),
    ...get('luExciseTaxTypes', ['luExciseTaxTypes']),
    ...get('luExciseTransactionType', ['luExciseTransactionType']),
    ...get('project', ['selectedProject']),
    ...get('exciseExceptions', ['saving']),
    selectedCounty() {
      if (this.selectedExciseExceptions.CountyID > 0) {
      }
    },
    taxRate() {
      return parseFloat(
        this.getTaxRate(
          this.selectedExciseExceptions.IssueID,
          this.selectedExciseExceptions.InvoiceDate,
          this.selectedExciseExceptions.DeliveredToCountyID,
          // this.selectedExciseExceptions.SoldFromCountyID,
          this.selectedExciseExceptions.TaxTypeID,
          this.selectedExciseExceptions.DeliveredToCityID,
          this.selectedExciseExceptions.SpecialDistrictID
        ).toFixed(4)
      )
    },
    taxDue() {
      // console.devlog(
      //   this.selectedExciseExceptions.TaxableAmount,
      //   this.taxRate,
      //   this.selectedExciseExceptions.TaxPaid
      // )
      return parseFloat(
        (
          this.selectedExciseExceptions.TaxableAmount * this.taxRate -
          this.selectedExciseExceptions.TaxPaid
        ).toFixed(2)
      )
    },
    filteredReferences() {
      var list = this.references
      if (this.selectedExciseExceptions.TransactionTypeID > 0) {
        list = list.filter(
          (x) =>
            x.TransactionTypeID ==
            this.selectedExciseExceptions.TransactionTypeID
        )
      }
      if (this.selectedExciseExceptions.TaxTypeID > 0) {
        list = list.filter(
          (x) => x.TaxTypeID == this.selectedExciseExceptions.TaxTypeID
        )
      }
      if (this.selectedExciseExceptions.IssueID > 0) {
        list = list.filter(
          (x) => x.IssueID == this.selectedExciseExceptions.IssueID
        )
      }
      var mappedList = [
        'Sales Invoices',
        'Purchase Invoices',
        'Depreciation Schedule',
        'Reconciliation',
      ].concat(
        list.map((item) => {
          return item.Reference
        })
      )

      return mappedList.filter((v, i, a) => a.indexOf(v) == i)
    },
    filteredVendorCustomer() {
      var list = this.vendorCustomer
      if (this.selectedExciseExceptions.TransactionTypeID > 0) {
        list = list.filter(
          (x) =>
            x.TransactionTypeID ==
            this.selectedExciseExceptions.TransactionTypeID
        )
      }
      if (this.selectedExciseExceptions.TaxTypeID > 0) {
        list = list.filter(
          (x) => x.TaxTypeID == this.selectedExciseExceptions.TaxTypeID
        )
      }
      if (this.selectedExciseExceptions.IssueID > 0) {
        list = list.filter(
          (x) => x.IssueID == this.selectedExciseExceptions.IssueID
        )
      }
      var mappedList = list.map((item) => {
        return item.VendorCustomer
      })
      return mappedList.filter((v, i, a) => a.indexOf(v) == i)
    },
    filteredCities() {
      if (this.selectedExciseExceptions.DeliveredToCountyID > 0) {
        let match = this.luCounty.find(
          (x) => x.ID == this.selectedExciseExceptions.DeliveredToCountyID
        )
        if (match) {
          return match.Cities.filter(
            (x) => x.IsActive && x.DORJurisdiction != null
          )
        }
      } else {
        return this.luCity.filter(
          (x) => x.IsActive && x.DORJurisdiction != null
        )
      }
    },
    availableTaxTypes() {
      var list = this.luExciseTaxTypes.filter((x) => true)
      if (this.selectedExciseExceptions.TransactionTypeID > 0) {
        let match = this.luExciseTransactionType.find(
          (x) => x.ID == this.selectedExciseExceptions.TransactionTypeID
        )
        if (match) {
          list = match.TaxTypes
        }
      }
      return list.filter((item) => {
        return item.IsActive && item.ProjectDetailID == this.projectDetailsId
      })
    },
    availableTransactionTypes() {
      let list = this.luExciseTransactionType.filter((x) => true)
      if (this.selectedExciseExceptions.TaxTypeID > 0) {
        let match = this.luExciseTaxTypes.find(
          (x) => x.ID == this.selectedExciseExceptions.TaxTypeID
        )
        if (match) {
          list = match.TransactionTypes
        }
      }
      return list.filter((i) => i.IsActive)
    },
    availableCounties() {
      var list = []
      var fullList = this.luCounty
      list = this.luCounty.filter((i) => i.DORJurisdiction != null)

      // if existing record make sure the previous selection is available even if no longer active
      if (this.value > 0) {
        var selectedItem = fullList.find((i) => i.ID == this.value)
        if (!list.find((i) => i.ID == this.value) && selectedItem) {
          list.push(selectedItem)
        }
      }
      // sort by column
      list.sort((a, b) => {
        //set the property to sort on
        let textField = 'Name'
        let sort = a[textField] > b[textField] ? 1 : -1
        return sort
      })
      return list
    },
    filteredCounties() {
      if (this.selectedExciseExceptions.DeliveredToCityID > 0) {
        let match = this.luCity.find(
          (x) => x.ID == this.selectedExciseExceptions.DeliveredToCityID
        )
        if (match) {
          return match.Counties.filter((x) => x.DORJurisdiction != null)
        }
      } else {
        return this.availableCounties
      }
    },
    filteredSpecialDistrict() {
      var list = []
      var fullList = this.luSpecialDistrict
      if (this.selectedExciseExceptions.DeliveredToCountyID > 0) {
        let match = this.luCounty.find(
          (x) => x.ID == this.selectedExciseExceptions.DeliveredToCountyID
        )
        if (match) {
          list = match.SpecialDistricts.filter(
            (x) => x.IsActive && x.DORJurisdiction != null
          )
        }
      }
      // if existing record make sure the previous selection is available even if no longer active
      // if (this.value > 0) {
      //   var selectedItem = fullList.find((i) => i.ID == this.value)
      //   if (!list.find((i) => i.ID == this.value) && selectedItem) {
      //     list.push(selectedItem)
      //   }
      // }
      // sort by column
      list.sort((a, b) => {
        //set the property to sort on
        let textField = 'SpecialDistrict'
        let sort = a[textField] > b[textField] ? 1 : -1
        return sort
      })
      return list
    },
  },
  methods: {
    handleError: call('errors/handleError'),
    ...call('exciseExceptions', ['saveExciseExceptions']),
    loadLuCounty: call('luCounty/loadLuCounty'),
    loadLuCity: call('luCity/loadLuCity'),
    loadLuSpecialDistrict: call('luSpecialDistrict/loadLuSpecialDistrict'),
    loadLuExciseTaxTypes: call('luExciseTaxTypes/loadLuExciseTaxTypes'),
    loadLuExciseTransactionType: call(
      'luExciseTransactionType/loadLuExciseTransactionType'
    ),
    async validate() {
      this.$refs.form.validate()
    },
    editExciseExceptions(entry) {
      this.selectedExciseExceptions = new ExciseExceptions(entry)
      this.modalText = 'Edit Excise Exception'
      this.dialog = !this.dialog
    },
    addExciseExceptions() {
      this.modalText = 'Insert Excise Exception'
      this.selectedExciseExceptions = new ExciseExceptions()
      this.dialog = !this.dialog
    },
    formatPercent(val) {
      if (val != null && val != undefined) {
        return parseFloat((val * 100).toFixed(2)) + '%'
      }
    },
    saveExciseExceptionsDetails() {
      this.validate().then(() => {
        if (this.valid) {
          //templateTODO: if you want to save related item arrays you will need to add a getSaveData() function to the javascript class and call that instead
          this.saveExciseExceptions(
            this.selectedExciseExceptions.removeRelated()
          ).then(
            (res) => {
              this.$emit('refresh')
              this.$emit('itemAdded', res.data)
              this.selectedExciseExceptions = new ExciseExceptions()
              this.$refs.form.resetValidation()
            },
            (err) => {}
          )
          if (this.modalText == 'Edit Excise Exception') {
            this.dialog = false
          }
        }
      })
    },
    cancelEntry() {
      this.dialog = false
      this.selectedExciseExceptions = new ExciseExceptions()
      this.$refs.form.resetValidation()
    },
  },
  watch: {
    filteredCities(nval, oval) {
      // console.devlog(nval, oval)
      let match = nval.find(
        (x) => x.ID == this.selectedExciseExceptions.DeliveredToCityID
      )
      if (!match) {
        this.selectedExciseExceptions.DeliveredToCityID = 0
      }
    },
    filteredSpecialDistrict(nval, oval) {
      // console.devlog(nval, oval)
      let match = nval.find(
        (x) => x.ID == this.selectedExciseExceptions.SpecialDistrictID
      )
      if (!match) {
        this.selectedExciseExceptions.SpecialDistrictID = 0
      }
    },
    auditedTax(nval, oval) {
      if (
        this.reconcilliationIssues.includes(
          this.selectedExciseExceptions.IssueID
        )
      ) {
        this.selectedExciseExceptions.TaxableAmount = parseFloat(
          (nval / this.taxRate).toFixed(2)
        )
      }
    },
    taxRate(nval, oval) {
      if (
        this.reconcilliationIssues.includes(
          this.selectedExciseExceptions.IssueID
        )
      ) {
        this.selectedExciseExceptions.TaxableAmount = parseFloat(
          (this.auditedTax / nval).toFixed(2)
        )
      }
    },
  },
}
</script>
<style></style>
