import {ref, onMounted, computed} from 'vue'
import {get, sync, commit, call} from 'vuex-pathify'
import {useStore} from 'vuex'

export default function useExciseTaxRates(store) {
  const taxRateToCounty = computed(() =>
    store.get('exciseTaxRateToCounty/exciseTaxRateToCounty')
  )
  const taxRateToCountyStatewide = computed(() =>
    store.get('exciseTaxRateToCountyStatewide/exciseTaxRateToCountyStatewide')
  )
  const taxRateToCity = computed(() =>
    store.get('exciseTaxRateToCity/exciseTaxRateToCity')
  )
  const taxRateToSpecialDistrict = computed(() =>
    store.get('exciseTaxRateToSpecialDistrict/exciseTaxRateToSpecialDistrict')
  )
  const luExciseTaxRates = computed(() =>
    store.get('luExciseTaxRates/luExciseTaxRates')
  )
  const loadLuExciseTaxRates = async () => {
    return await store.dispatch('luExciseTaxRates/loadLuExciseTaxRates')
  }
  const loadExciseTaxRateToCounty = async () => {
    return await store.dispatch(
      'exciseTaxRateToCounty/loadExciseTaxRateToCounty'
    )
  }
  const loadExciseTaxRateToCountyStatewide = async () => {
    return await store.dispatch(
      'exciseTaxRateToCountyStatewide/loadExciseTaxRateToCountyStatewide'
    )
  }
  const loadExciseTaxRateToCity = async () => {
    return await store.dispatch('exciseTaxRateToCity/loadExciseTaxRateToCity')
  }
  const loadExciseTaxRateToSpecialDistrict = async () => {
    return await store.dispatch(
      'exciseTaxRateToSpecialDistrict/loadExciseTaxRateToSpecialDistrict'
    )
  }
  const rateLookup = (list, taxTypeID, invoiceDate, valueProperty, value) => {
    var filteredList = list.value
      .filter((x) => {
        return (
          x.IsActive &&
          x.TaxTypeID == taxTypeID &&
          x[valueProperty] == value &&
          new Date(x.EffectiveDate) <= new Date(invoiceDate)
        )
      })
      .sort((a, b) => {
        return new Date(b.EffectiveDate) - new Date(a.EffectiveDate)
      })
    if (filteredList.length > 0) {
      let taxRate = luExciseTaxRates.value.find(
        (x) => x.ID == filteredList[0].TaxRateID
      ).TaxRate

      return taxRate
    } else {
      return null
    }
  }
  const getTaxRate = (
    issueID,
    invoiceDate,
    delCounty,
    // fromCounty,
    taxType,
    city,
    spDist
  ) => {
    // console.ltBlue('startRateLookup')
    var returnValue = 0
    //4% Nonresident contractor retainage (no longer active)
    if (issueID == 13) {
      returnValue = 0.04
    } else if (issueID != 43) {
      //sales or use tax
      var statewideRate, delCountyRate, cityRate, spDistRate
      if ((taxType == 1 || (taxType == 2 && issueID != 49)) && issueID != 50) {
        //get statewide rate (county 24) for sales tax (tax type 1)
        statewideRate = rateLookup(
          taxRateToCountyStatewide,
          1,
          invoiceDate,
          'CountyID',
          delCounty
        )
        if (statewideRate == null) {
          statewideRate = rateLookup(
            taxRateToCounty,
            1,
            invoiceDate,
            'CountyID',
            24
          )
        }
        delCountyRate = rateLookup(
          taxRateToCounty,
          1,
          invoiceDate,
          'CountyID',
          delCounty
        )
        // fromCountyRate = rateLookup(
        //   taxRateToCounty,
        //   1,
        //   invoiceDate,
        //   'CountyID',
        //   fromCounty
        // )
        cityRate = rateLookup(taxRateToCity, 1, invoiceDate, 'CityID', city)
        spDistRate = rateLookup(
          taxRateToSpecialDistrict,
          1,
          invoiceDate,
          'SpecialDistrictID',
          spDist
        )
        // console.devlog(
        //   statewideRate,
        //   delCountyRate,
        //   fromCountyRate,
        //   cityRate,
        //   spDistRate
        // )
        // start with statewide rate
        if (statewideRate) {
          returnValue = statewideRate
        }
        //delivered to county is out of state, not lodging
        // if (delCounty == 99) {
        //   // add county portion of sales tax  (tax type 1)using fromCounty
        //   if (fromCountyRate) {
        //     returnValue += fromCountyRate
        //   }
        // }
        //delivered to county is in state, not lodging
        if (delCounty != 99) {
          //add county sales tax  (tax type 1) using deliveredCounty
          if (delCountyRate) {
            returnValue += delCountyRate
          }
        }
        if (city > 0 && issueID != 49) {
          // add city sales tax  (tax type 1)
          if (cityRate) {
            returnValue += cityRate
          }
        }
        if (spDist > 0) {
          // add special district sales tax  (tax type 1)
          if (spDistRate) {
            returnValue += spDistRate
          }
        }
      }
      //lodging
      if (taxType == 3 && issueID != 49) {
        //get statewide rate (county 24) for lodging tax (tax type 3)
        statewideRate = rateLookup(
          taxRateToCountyStatewide,
          taxType,
          invoiceDate,
          'CountyID',
          delCounty
        )
        if (statewideRate == null) {
          statewideRate = rateLookup(
            taxRateToCounty,
            taxType,
            invoiceDate,
            'CountyID',
            24
          )
        }
        delCountyRate = rateLookup(
          taxRateToCounty,
          taxType,
          invoiceDate,
          'CountyID',
          delCounty
        )
        cityRate = rateLookup(
          taxRateToCity,
          taxType,
          invoiceDate,
          'CityID',
          city
        )
        spDistRate = rateLookup(
          taxRateToSpecialDistrict,
          taxType,
          invoiceDate,
          'SpecialDistrictID',
          spDist
        )
        // start with statewide rate
        if (statewideRate) {
          returnValue = statewideRate
        }
        if (!city && !spDist) {
          //add county lodging tax
          returnValue += delCountyRate
        }
        if (city > 0) {
          // add city sales tax  (tax type 1)

          if (cityRate == null || cityRate == 0) {
            // use county lodging rate instead
            returnValue += delCountyRate
          } else {
            returnValue += cityRate
          }
        }
        if (spDist > 0 && issueID != 50) {
          // add special district sales tax  (tax type 1)
          if (spDistRate == null || spDistRate == 0) {
            // use county lodging rate instead
            returnValue += delCountyRate
          } else {
            returnValue += spDistRate
          }
        }
      }
    }
    // console.ltBlue('finishRateLookup')
    return parseFloat(returnValue.toFixed(4))
    // //43 not in use
    //former tax types: lodging = 10, use = 11
    // if (
    //   issueID != 8 &&
    //   issueID != 13 &&
    //   issueID != 43 &&
    //   issueID != 49 &&
    //   issueID != 50
    // ) {
    //   //not lodging (sales or use tax)
    //   if (taxType != 10) {
    //     //get statewide rate (county 24) for sales tax (tax type 1)

    //     //delivered to county is out of state, not lodging
    //     if (delCounty == 99) {
    //       // add county portion of sales tax  (tax type 1)using fromCounty
    //     }
    //     //delivered to county is in state, not lodging
    //     if (delCounty != 99) {
    //       //add county sales tax  (tax type 1) using deliveredCounty
    //     }
    //     if (city > 0) {
    //       // add city sales tax  (tax type 1)
    //     }
    //     if (spDist > 0) {
    //       // add special district sales tax  (tax type 1)
    //     }
    //   }
    //   // is lodging
    //   if (taxType == 10) {
    //     //get statewide rate (county 24) for lodging tax (tax type 10)

    //     if (!city && !spDist) {
    //       //add county lodging tax
    //     }
    //     if (city > 0) {
    //       // add city sales tax  (tax type 1)

    //       if (cityRate == null || cityRate == 0) {
    //         // use county lodging rate instead
    //       }
    //     }
    //     if (spDist > 0) {
    //       // add special district sales tax  (tax type 1)

    //       if (sdRate == null || sdRate == 0) {
    //         // use county lodging rate instead
    //       }
    //     }
    //   }
    // }
    // //get sales tax for lodging services
    // if (issueID == 49 && taxType == 1) {
    //   //get statewide sales tax rate

    //   //delivered to county is out of state
    //   if (delCounty == 99) {
    //     // add county portion of sales tax  (tax type 1)using fromCounty
    //   }
    //   //delivered to county is in state
    //   if (delCounty != 99) {
    //     //add county sales tax  (tax type 1) using deliveredCounty
    //   }
    //   if (spDist > 0) {
    //     // add special district sales tax  (tax type 1)
    //   }
    // }
    // //lodging services
    // if (issueID == 50 && taxType == 10) {
    //   //get statewide rate (county 24) for lodging tax (tax type 10)
    // }
    // if (!city && !spDist) {
    //   //add delivered to county lodging tax
    // }
    // if (city > 0) {
    //   // add city sales tax  (tax type 1)

    //   if (cityRate == null || cityRate == 0) {
    //     // use county lodging rate instead
    //   }
    // }
    // //lodging tax for reconciliation
    // if (issueID == 8 && taxType == 10) {
    //   //get statewide rate (county 24) for lodging tax (tax type 10)

    //   if (!city && !spDist) {
    //     //add county lodging tax
    //   }
    //   if (city > 0) {
    //     // add city sales tax  (tax type 1)

    //     if (cityRate == null || cityRate == 0) {
    //       // use county lodging rate instead
    //     }
    //   }
    //   if (spDist > 0 && issueID != 50) {
    //     // add special district sales tax  (tax type 1)

    //     if (sdRate == null || sdRate == 0) {
    //       // use county lodging rate instead
    //     }
    //   }
    // }
    // //sales and use tax for reconciliation
    // if (issueID == 8 && (taxType == 1 || taxType == 11)) {
    //   //get statewide rate (county 24) for sales tax (tax type 1)

    //   //delivered to county is out of state, not lodging
    //   if (delCounty == 99) {
    //     // add county portion of sales tax  (tax type 1)using fromCounty
    //   }
    //   //delivered to county is in state, not lodging
    //   if (delCounty != 99) {
    //     //add county sales tax  (tax type 1) using deliveredCounty
    //   }
    //   if (city > 0) {
    //     // add city sales tax  (tax type 1)
    //   }
    //   if (spDist > 0) {
    //     // add special district sales tax  (tax type 1)
    //   }
    // }
  }
  onMounted(loadLuExciseTaxRates)
  onMounted(loadExciseTaxRateToCounty)
  onMounted(loadExciseTaxRateToCountyStatewide)
  onMounted(loadExciseTaxRateToCity)
  onMounted(loadExciseTaxRateToSpecialDistrict)
  return {
    getTaxRate,
  }
}
