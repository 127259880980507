<template>
  <BaseAutocomplete
    v-bind="$attrs"
    :items="availableItems"
    :label="label"
    v-model="selected"
    :item-text="itemText"
    item-value="ID"
    :clearable="clearable"
    :search-input.sync="searchInput"
    :loading="loading"
    @change="searchInput = ''"
    :rules="rules"
  >
    <template
      v-for="(_, scopedSlotName) in $scopedSlots"
      v-slot:[scopedSlotName]="slotData"
    >
      <slot :name="scopedSlotName" v-bind="slotData" />
    </template>
    <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
      <slot :name="slotName" />
    </template>
  </BaseAutocomplete>
</template>
<script>
import {get, sync, commit, call} from 'vuex-pathify'
export default {
  name: 'autocomplete-luExciseTransactionType',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    label: {
      type: String,
      default: 'Excise Transaction Type',
    },
    value: Number,
    clearable: {
      type: Boolean,
      default: false,
    },
    rules: Array,
    items: Array,
  },
  data() {
    return {
      //templateFIX: if there is more than one pick one to display
      itemText: 'Description',
      searchInput: '',
    }
  },
  watch: {
    value(nval, oval) {
      if (nval != oval) {
        this.searchInput = ''
      }
    },
  },
  computed: {
    ...get('luExciseTransactionType', ['luExciseTransactionType', 'loading']),
    availableItems() {
      var list = []
      var fullList = []
      if (this.items) {
        fullList = this.items
        list = this.items.filter((i) => true)
      } else {
        fullList = this.luExciseTransactionType
        list = this.luExciseTransactionType.filter((i) => true)
      }
      //filter for only active items
      list = list.filter((i) => i.IsActive)
      // if existing record make sure the previous selection is available even if no longer active
      if (this.value > 0) {
        var selectedItem = fullList.find((i) => i.ID == this.value)
        if (!list.find((i) => i.ID == this.value) && selectedItem) {
          list.push(selectedItem)
        }
      }
      // sort by column
      list.sort((a, b) => {
        //set the property to sort on
        let textField = this.itemText
        let sort = a[textField] > b[textField] ? 1 : -1
        return sort
      })
      return list
    },
    selected: {
      get() {
        if (this.value) {
          return this.value
        } else {
          return null
        }
      },
      set(newValue) {
        this.$emit('change', newValue)
      },
    },
  },
  created() {
    if (!this.items) {
      this.loadLuExciseTransactionType()
    }
  },
  methods: {
    loadLuExciseTransactionType: call(
      'luExciseTransactionType/loadLuExciseTransactionType'
    ),
  },
}
</script>
<style>
</style>